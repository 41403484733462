var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-my" },
    [
      _c(
        "div",
        { staticClass: "account clearfix" },
        [
          _c("div", { staticClass: "account-wen" }, [
            _c("div", [_vm._v("我的信息")]),
            _vm.grade
              ? _c("div", [
                  _vm._v("\n        会员等级：\n        "),
                  _c("span", [_vm._v(_vm._s(_vm.grade.member_level_id))])
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "flex-end",
                "margin-bottom": "30px"
              }
            },
            [
              _c("div", { staticClass: "account-head-portrait" }, [
                _vm.portrait
                  ? _c("div", {
                      staticClass: "bg",
                      style: {
                        backgroundImage: "url(" + _vm.portrait + ")",
                        backgroundSize: "cover"
                      }
                    })
                  : _c("div", { staticClass: "bg" }),
                _c("div", { staticStyle: { "padding-bottom": "100%" } })
              ]),
              _c("div", { staticClass: "btn", on: { click: _vm.btnPor } }, [
                _vm._v(_vm._s(_vm.portrait ? "修改头像" : "上传头像"))
              ])
            ]
          ),
          _c("upload", {
            on: { getImageUrl: _vm.getImageUrl },
            model: {
              value: _vm.visiblePro,
              callback: function($$v) {
                _vm.visiblePro = $$v
              },
              expression: "visiblePro"
            }
          }),
          _c("div", { staticClass: "account-xx" }, [
            _vm.info.username
              ? _c("div", [_vm._v("名称：" + _vm._s(_vm.info.username))])
              : _vm._e(),
            _vm.info.mobile
              ? _c("div", [_vm._v("手机号：" + _vm._s(_vm.info.mobile))])
              : _vm._e(),
            _vm.info.province
              ? _c("div", [_vm._v("地址：" + _vm._s(_vm.info.province))])
              : _vm._e(),
            _vm.info.company
              ? _c("div", [_vm._v("公司名称：" + _vm._s(_vm.info.company))])
              : _vm._e(),
            _vm.info.address
              ? _c("div", [_vm._v("公司地址：" + _vm._s(_vm.info.address))])
              : _vm._e(),
            _vm.info.phone
              ? _c("div", [_vm._v("联系方式：" + _vm._s(_vm.info.phone))])
              : _vm._e(),
            _vm.info.fangxiangs
              ? _c("div", [_vm._v("从事方向：" + _vm._s(_vm.info.fangxiangs))])
              : _vm._e(),
            _vm.info.email
              ? _c("div", [_vm._v("电子邮箱：" + _vm._s(_vm.info.email))])
              : _vm._e(),
            _c("div", [
              _vm._v("总积分：" + _vm._s(_vm.integral ? _vm.integral : "0"))
            ])
          ]),
          _c(
            "div",
            { staticClass: "btn right", on: { click: _vm.btnmodify } },
            [_vm._v("修改信息")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "account clearfix" }, [
        _vm._m(0),
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "secondary" }, [_vm._v("已注册产品")]),
          _c(
            "div",
            { staticClass: "account-xx", style: _vm.styleObj },
            [
              _vm.allproductres.length === 0
                ? _c(
                    "div",
                    {
                      staticClass: "no",
                      on: {
                        click: function($event) {
                          return _vm.btn(1)
                        }
                      }
                    },
                    [_vm._v("暂无注册商品，请去注册")]
                  )
                : _vm._l(_vm.allproductres, function(item) {
                    return _c(
                      "div",
                      { key: item.id, staticStyle: { width: "25%" } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  })
            ],
            2
          )
        ]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              "align-items": "flex-end",
              "margin-bottom": "30px"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "secondary",
                staticStyle: { "margin-bottom": "0" }
              },
              [_vm._v("支持中心")]
            ),
            _c(
              "div",
              {
                staticClass: "btn",
                staticStyle: { width: "150px" },
                on: {
                  click: function($event) {
                    return _vm.btn(2)
                  }
                }
              },
              [_vm._v("查看售后相关信息")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "account clearfix" }, [
        _vm._m(1),
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "secondary" }, [_vm._v("我的帖子")]),
          _c(
            "div",
            { staticClass: "account-xx", style: _vm.posStyleObj },
            [
              _vm.postList.length === 0
                ? _c("div", { staticClass: "no" }, [
                    _vm._v("您还没有帖子发布，请去发布")
                  ])
                : _vm._e(),
              _vm._l(_vm.postList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    on: {
                      click: function($event) {
                        return _vm.btnlxp(item.id)
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("标题:" + _vm._s(item.title))]),
                    _c("div", [
                      _vm._v(
                        "时间:" +
                          _vm._s(
                            _vm
                              .$moment(+item.create_time)
                              .format("YYYY-MM-DD HH:mm")
                          )
                      )
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm.postList.length !== 0
            ? _c(
                "div",
                {
                  staticClass: "btn right",
                  on: {
                    click: function($event) {
                      return _vm.btnAll(_vm.postList)
                    }
                  }
                },
                [_vm._v("查看更多")]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "secondary" }, [_vm._v("点赞提醒")]),
          _c(
            "div",
            { staticClass: "account-xx", style: _vm.likeStyleObj },
            [
              _vm.likeList.length === 0
                ? _c("div", { staticClass: "no" }, [
                    _vm._v("您的帖子没有收到点赞")
                  ])
                : _vm._e(),
              _vm._l(_vm.likeList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    on: {
                      click: function($event) {
                        return _vm.btnlxp(item.id)
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("标题:" + _vm._s(item.title))]),
                    _c("div", [_vm._v("名称:" + _vm._s(item.hfuser))]),
                    _c("div", [
                      _vm._v("\n            时间:\n            "),
                      item.create_time
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$moment(+item.create_time)
                                  .format("YYYY-MM-DD HH:mm")
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm.likeList.length !== 0
            ? _c(
                "div",
                {
                  staticClass: "btn right",
                  on: {
                    click: function($event) {
                      return _vm.btnAll(_vm.likeList)
                    }
                  }
                },
                [_vm._v("查看更多")]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "secondary" }, [_vm._v("留言回复")]),
          _c(
            "div",
            { staticClass: "account-xx", style: _vm.replyStyleObj },
            [
              _vm.replyList.length === 0
                ? _c("div", { staticClass: "no" }, [
                    _vm._v("您的帖子没有收到回复")
                  ])
                : _vm._e(),
              _vm._l(_vm.replyList, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    on: {
                      click: function($event) {
                        return _vm.btnlxp(item.id)
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("标题:" + _vm._s(item.title))]),
                    _c("div", [_vm._v("名称:" + _vm._s(item.hfuser))]),
                    _c("div", [
                      _vm._v("\n            时间:\n            "),
                      item.create_time
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$moment(+item.create_time)
                                  .format("YYYY-MM-DD HH:mm")
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              })
            ],
            2
          ),
          _vm.replyList.length !== 0
            ? _c(
                "div",
                {
                  staticClass: "btn right",
                  on: {
                    click: function($event) {
                      return _vm.btnAll(_vm.replyList)
                    }
                  }
                },
                [_vm._v("查看更多")]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "clearfix" }, [
          _c("div", { staticClass: "secondary" }, [_vm._v("官方提醒")]),
          _c(
            "div",
            { staticClass: "account-xx", style: _vm.announcementStyleObj },
            [
              _vm.announcementList.length === 0
                ? _c("div", { staticClass: "no" }, [_vm._v("暂无官方公告")])
                : _vm._e(),
              _vm._l(_vm.announcementList, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    on: {
                      click: function($event) {
                        return _vm.btnannouncement(item)
                      }
                    }
                  },
                  [
                    _c("div", [_vm._v("标题:" + _vm._s(item.name))]),
                    _c("div", [
                      _vm._v("\n            时间:\n            "),
                      item.create_time
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$moment(+item.create_time)
                                  .format("YYYY-MM-DD HH:mm")
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              })
            ],
            2
          )
        ])
      ]),
      _c("all", {
        attrs: { lists: _vm.lists },
        model: {
          value: _vm.isall,
          callback: function($$v) {
            _vm.isall = $$v
          },
          expression: "isall"
        }
      }),
      _c("modify", {
        model: {
          value: _vm.ismodify,
          callback: function($$v) {
            _vm.ismodify = $$v
          },
          expression: "ismodify"
        }
      }),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.announcement.name, visible: _vm.visible },
          on: { ok: _vm.handleOkReport, cancel: _vm.handleCancelReport }
        },
        [
          _vm.announcement
            ? _c("div", {
                attrs: { id: "announcement" },
                domProps: { innerHTML: _vm._s(_vm.announcement.content) }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "account-wen" }, [
      _c("div", [_vm._v("我的产品")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "account-wen" }, [
      _c("div", [_vm._v("我的帖子")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }