<template>
  <div v-if="lists.length">
    <a-modal :visible="visible" :footer="null" @ok="handleOk" @cancel="handleCancel">
      <div class="allList" v-for="(item,index) in lists" :key="index" @click="btnlxp(item.id)">
        <div v-if="item.title">标题:{{item.title}}</div>
        <div v-if="item.name">标题:{{item.name}}</div>
        <div v-if="item.hfuser">用户名：{{item.hfuser}}</div>
        <div>
          时间:
          <span
            v-if="item.create_time"
          >{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: VueTypes.bool.def(false),
    lists: VueTypes.Array,
  },
  data() {
    return {};
  },
  methods: {
    handleOk(e) {
      this.$emit('change', false);
    },
    handleCancel(e) {
      this.$emit('change', false);
    },
    btnlxp(id) {
      this.$router.push('/forum/details/' + id);
    },
  },
};
</script>
<style lang="less" scoped>
.allList {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 0;
  margin: 15px 0;
  border-bottom: 1px solid #ccc;
  div {
  }
}
</style>