<template>
  <div>
    <a-modal title="修改信息" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="用户名">
          <a-input v-decorator="['username']" />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input v-decorator="['password']" />
        </a-form-item>

        <a-form-item label="电话">
          <a-input v-decorator="['mobile']" />
        </a-form-item>

        <a-form-item label="邮箱">
          <a-input v-decorator="['email']" />
        </a-form-item>
        <a-form-item label="从业方向">
          <a-select v-decorator="['fangxiang']">
            <template v-for="item in works">
              <a-select-option :key="item.id">{{ item.name }}</a-select-option>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="公司名称" v-if="info.company">
          <a-input v-decorator="['company']" />
        </a-form-item>
        <a-form-item label="公司电话" v-if="info.phone">
          <a-input v-decorator="['phone']" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
import { mapState, mapMutations } from 'vuex';
export default {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: VueTypes.bool.def(false),
  },
  data() {
    return {
      form: this.$form.createForm(this),
      works: [],
    };
  },
  watch: {
    visible(n) {
      if (n) {
        let info = {
          username: this.info.username,
          mobile: this.info.mobile,
          email: this.info.email,
          fangxiang: this.info.fangxiang,
          company: this.info.company,
          phone: this.info.phone,
        };
        this.$nextTick(() => {
          this.form.setFieldsValue(info);
        });
      }
    },
  },
  mounted() {
    this.work();
  },
  computed: {
    ...mapState('user', ['info']),
  },
  methods: {
    ...mapMutations('user', ['login']),
    work() {
      return this.$request(Apis.register.WORKS).then(data => {
        this.works = data.data.data;
      });
    },
    handleSubmit(e) {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.token = this.info.token;
          
          return this.$request(Apis.my.UPDATED, { data: values }).then(data => {
            let users = data.data.users;
           
            localStorage.setItem('data', JSON.stringify(users));
            this.login(JSON.parse(localStorage.getItem('data')));
            this.$emit('change', false);
          });
        }
      });
    },
    handleOk(e) {
      this.handleSubmit();
      //   
    },
    handleCancel(e) {
      this.$emit('change', false);
    },
  },
};
</script>