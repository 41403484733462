<template>
  <div class="page-my">
    <div class="account clearfix">
      <div class="account-wen">
        <div>我的信息</div>
        <div v-if="grade">
          会员等级：
          <span>{{grade.member_level_id}}</span>
        </div>
      </div>
      <div
        style="display: flex;justify-content: space-between;align-items: flex-end;margin-bottom:30px;"
      >
        <div class="account-head-portrait">
          <div
            class="bg"
            v-if="portrait"
            :style="{ backgroundImage: 'url(' + portrait + ')',backgroundSize:'cover' }"
          ></div>
          <div class="bg" v-else></div>
          <div style="padding-bottom:100%;"></div>
        </div>
        <div class="btn" @click="btnPor">{{ portrait ? '修改头像' : '上传头像' }}</div>
      </div>
      <upload v-model="visiblePro" @getImageUrl="getImageUrl" />
      <div class="account-xx">
        <div v-if="info.username">名称：{{info.username}}</div>
        <div v-if="info.mobile">手机号：{{info.mobile}}</div>
        <div v-if="info.province">地址：{{info.province}}</div>
        <div v-if="info.company">公司名称：{{info.company}}</div>
        <div v-if="info.address">公司地址：{{info.address}}</div>
        <div v-if="info.phone">联系方式：{{info.phone}}</div>
        <div v-if="info.fangxiangs">从事方向：{{info.fangxiangs}}</div>
        <div v-if="info.email">电子邮箱：{{info.email}}</div>
        <div>总积分：{{integral?integral:'0'}}</div>
      </div>
      <div class="btn right" @click="btnmodify">修改信息</div>
    </div>
    <div class="account clearfix">
      <div class="account-wen">
        <div>我的产品</div>
      </div>
      <div class="clearfix">
        <div class="secondary">已注册产品</div>
        <div class="account-xx" :style="styleObj">
          <div class="no" v-if="allproductres.length===0" @click="btn(1)">暂无注册商品，请去注册</div>
          <div style="width:25%" v-else v-for="item in allproductres" :key="item.id">{{item.name}}</div>
        </div>
        <!-- <div class="btn right">查看更多</div> -->
      </div>

      <div
        style="display: flex;justify-content: space-between;align-items: flex-end;margin-bottom:30px;"
      >
        <div class="secondary" style="margin-bottom:0;">支持中心</div>
        <div class="btn" style="width:150px" @click="btn(2)">查看售后相关信息</div>
      </div>
    </div>
    <div class="account clearfix">
      <div class="account-wen">
        <div>我的帖子</div>
      </div>
      <div class="clearfix">
        <div class="secondary">我的帖子</div>
        <div class="account-xx" :style="posStyleObj">
          <div class="no" v-if="postList.length===0">您还没有帖子发布，请去发布</div>
          <div class="item" v-for="item in postList" :key="item.id" @click="btnlxp(item.id)">
            <div>标题:{{item.title}}</div>

            <div>时间:{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
          </div>
        </div>
        <div class="btn right" v-if="postList.length!==0" @click="btnAll(postList)">查看更多</div>
      </div>
      <div class="clearfix">
        <div class="secondary">点赞提醒</div>
        <div class="account-xx" :style="likeStyleObj">
          <div class="no" v-if="likeList.length===0">您的帖子没有收到点赞</div>
          <div class="item" v-for="(item,index) in likeList" :key="index" @click="btnlxp(item.id)">
            <div>标题:{{item.title}}</div>
            <div>名称:{{item.hfuser}}</div>
            <div>
              时间:
              <span
                v-if="item.create_time"
              >{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</span>
            </div>
          </div>
        </div>
        <div class="btn right" v-if="likeList.length!==0" @click="btnAll(likeList)">查看更多</div>
      </div>
      <div class="clearfix">
        <div class="secondary">留言回复</div>
        <div class="account-xx" :style="replyStyleObj">
          <div class="no" v-if="replyList.length===0">您的帖子没有收到回复</div>
          <div class="item" v-for="(item,index) in replyList" :key="index" @click="btnlxp(item.id)">
            <div>标题:{{item.title}}</div>
            <div>名称:{{item.hfuser}}</div>
            <div>
              时间:
              <span
                v-if="item.create_time"
              >{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</span>
            </div>
          </div>
        </div>
        <div class="btn right" v-if="replyList.length!==0" @click="btnAll(replyList)">查看更多</div>
      </div>
      <div class="clearfix">
        <div class="secondary">官方提醒</div>
        <div class="account-xx" :style="announcementStyleObj">
          <div class="no" v-if="announcementList.length===0">暂无官方公告</div>
          <div
            class="item"
            v-for="item in announcementList"
            :key="item.id"
            @click="btnannouncement(item)"
          >
            <div>标题:{{item.name}}</div>
            <div>
              时间:
              <span
                v-if="item.create_time"
              >{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</span>
            </div>
          </div>
        </div>
        <!-- <div
          class="btn right"
          v-if="announcementList.length!==0"
          @click="btnAll(announcementList)"
        >查看更多</div>-->
      </div>
    </div>
    <all v-model="isall" :lists="lists" />
    <modify v-model="ismodify" />
    <a-modal
      :title="announcement.name"
      :visible="visible"
      @ok="handleOkReport"
      @cancel="handleCancelReport"
    >
      <div id="announcement" v-if="announcement" v-html="announcement.content"></div>
    </a-modal>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import upload from './components/upload';
import all from './components/all';
import modify from './components/modify';
export default {
  components: { upload, all, modify },
  data() {
    return {
      portrait: '',
      integral: '',
      allproductres: [],
      visiblePro: false,
      isall: false,
      ismodify: false,
      postList: [],
      likeList: [],
      replyList: [],
      announcementList: [],
      announcement: {},
      visible: false,
      lists: [],
      grade: '',
    };
  },
  created() {
    if (_.isEmpty(this.info)) {
      this.$message.warning('您还没有登录，请您去登录');
      this.$router.push('/');
    }
  },
  watch: {
    info(n) {
      if (_.isEmpty(n)) {
        this.$message.warning('您还没有登录，请您去登录');
        this.$router.push('/');
      }
    },
  },
  computed: {
    ...mapState('user', ['info']),
    styleObj() {
      return this.allproductres.length === 0 ? { marginBottom: '30px' } : { marginBottom: '30px' };
    },
    posStyleObj() {
      return this.postList.length === 0 ? { marginBottom: '30px' } : { marginBottom: '30px' };
    },
    likeStyleObj() {
      return this.likeList.length === 0 ? { marginBottom: '30px' } : '';
    },
    replyStyleObj() {
      return this.replyList.length === 0 ? { marginBottom: '30px' } : '';
    },
    announcementStyleObj() {
      return this.announcementList.length === 0 ? { marginBottom: '30px' } : '';
    },
  },
  mounted() {
    this.productres();
    this.getIntegral();
    if (this.info.file) {
      this.portrait = this.info.file;
    }
  },
  methods: {
    btnAll(lists) {
      this.isall = true;
      this.lists = lists;
    },
    btnmodify() {
      this.ismodify = true;
    },
    btnPor() {
      this.visiblePro = true;
    },
    getImageUrl(e) {
      this.portrait = e;
    },
    productres() {
      if (_.isEmpty(this.info)) {
        return;
      } else {
        this.gteproductres();
        this.getwodetz();
        this.getwodetzzs();
        this.getwodelys();
        this.getannouncement();
        this.getGrade();
      }
    },
    getIntegral() {
      return this.$request(Apis.my.JIFEN, { data: { token: this.info.token } }).then(data => {
        this.integral = data.data.num;
      });
    },
    gteproductres() {
      return this.$request(Apis.my.PRODUCTRES, { data: { token: this.info.token } }).then(data => {
        this.allproductres = data.data.data;
      });
    },
    getwodetz() {
      return this.$request(Apis.my.WODETZ, { data: { token: this.info.token } }).then(data => {
        this.postList = data.data.data;
      });
    },
    getwodetzzs() {
      return this.$request(Apis.my.WODETZZS, { data: { token: this.info.token } }).then(data => {
        this.likeList = data.data.data;
      });
    },
    getwodelys() {
      return this.$request(Apis.my.WODELYS, { data: { token: this.info.token } }).then(data => {
        this.replyList = data.data.data;
      });
    },
    getannouncement() {
      return this.$request(Apis.my.GFGG, { data: { token: this.info.token } }).then(data => {
        this.announcementList = data.data.data;
      });
    },
    getGrade() {
      return this.$request(Apis.my.DENGJI, { data: { token: this.info.token } }).then(data => {
        
        this.grade = data.data.data;
      });
    },
    btnlxp(id) {
      this.$router.push('/forum/details/' + id);
    },
    btnannouncement(item) {
      this.visible = true;
      this.announcement = item;
      setTimeout(() => {
        let Hwidth = $('#announcement').width();
        let imdwidth = $('#announcement p img').width();
        if (imdwidth >= Hwidth) {
          $('#announcement p img').css('width', '100%');
        }
      }, 10);
    },
    btn(num) {
      if (num === 1) {
        this.$router.push('/productRegistration');
      } else if (num === 2) {
        this.$router.push('/aboutUs');
      }
    },
    handleOkReport() {
      this.visible = false;
    },
    handleCancelReport() {
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.page-my {
  padding: 0 50px;
  .account {
    .account-wen {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ccc;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
      div {
        span {
          color: #ffcc00;
        }
      }
    }
    .secondary {
      font-size: 20px;
      color: #ccc;
      margin-bottom: 30px;
    }
    .secondary:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffcc00;
      display: inline-block;
      margin: 0 10px;
    }
    .account-head-portrait {
      width: 20%;
      position: relative;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background: #ccc;
        border-radius: 50%;
      }
    }
    .btn {
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #ffcc00;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      font-weight: 600;
    }
    .right {
      float: right;
      margin: 30px 0;
    }
    .clearfix:before,
    .clearfix:after {
      content: '';
      display: table;
    }
    .clearfix:after {
      clear: both;
    }
    .clearfix {
      zoom: 1;
    }
    .account-xx {
      border: 1px dashed #ccc;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .no {
        margin: auto;
        text-align: center;
        padding: 50px 0;
        cursor: pointer;
      }
      div {
        width: 50%;
        font-size: 16px;
        color: #ccc;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item {
        width: 80%;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 10px auto;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        div {
          padding: 0;
        }
      }
    }
  }
}
</style>