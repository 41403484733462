<template>
  <div class="my-upload">
    <a-modal title="上传头像" :visible="visible" @ok="handleOk" @cancel="handleCancelModal">
      <a-upload
        name="file"
        listType="picture-card"
        class="avatar-uploader"
        :fileList="fileList"
        :customRequest="customRequest"
      >
        <div class="touxiang" v-if="imageUrl">
          <div
            class="bg"
            :style="{ backgroundImage: 'url(' + imageUrl + ')',backgroundSize:'cover' }"
          ></div>
          <div class="position" style="padding-bottom:100%"></div>
        </div>

        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传头像</div>
        </div>
      </a-upload>
    </a-modal>
  </div>
</template>
<script>
// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }
import VueTypes from 'vue-types';
import { mapState, mapMutations } from 'vuex';
export default {
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: VueTypes.bool.def(false),
  },
  data() {
    return {
      loading: false,
      imageUrl: '',
      fileList: [],
    };
  },
  computed: {
    ...mapState('user', ['info']),
  },
  methods: {
    ...mapMutations('user', ['logoimg']),
    handleOk(e) {
      this.$emit('change', false);
    },
    handleCancelModal(e) {
      this.$emit('change', false);
    },
    customRequest(data) {
      const formData = new FormData();
      formData.append('file', data.file);

      // formData.append('token', 'aiufpaidfupipiu')//随便写一个token示例
      this.saveFile(formData);
    },
    saveFile(formData) {
      this.$request(Apis.my.XIUGAI, { data: formData })

        .then(response => {
          
          this.imageUrl = response.data.touxiang;
          localStorage.setItem('data', JSON.stringify(this.imageUrl));
          this.logoimg(JSON.parse(localStorage.getItem('data')));
          this.$emit('getImageUrl', JSON.parse(localStorage.getItem('data')));
        })
        .catch(function(error) {});
    },
  },
};
</script>
<style lang="less" scoped>
.touxiang {
  width: 100%;
  position: relative;
  margin: auto;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}
</style>