var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-upload" },
    [
      _c(
        "a-modal",
        {
          attrs: { title: "上传头像", visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancelModal }
        },
        [
          _c(
            "a-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                name: "file",
                listType: "picture-card",
                fileList: _vm.fileList,
                customRequest: _vm.customRequest
              }
            },
            [
              _vm.imageUrl
                ? _c("div", { staticClass: "touxiang" }, [
                    _c("div", {
                      staticClass: "bg",
                      style: {
                        backgroundImage: "url(" + _vm.imageUrl + ")",
                        backgroundSize: "cover"
                      }
                    }),
                    _c("div", {
                      staticClass: "position",
                      staticStyle: { "padding-bottom": "100%" }
                    })
                  ])
                : _c(
                    "div",
                    [
                      _c("a-icon", {
                        attrs: { type: _vm.loading ? "loading" : "plus" }
                      }),
                      _c("div", { staticClass: "ant-upload-text" }, [
                        _vm._v("上传头像")
                      ])
                    ],
                    1
                  )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }